import * as React from "react";
import { FinishYourBooking as FinishYourBookingSharedUI } from "@shared-ui/retail-storefront-finish-your-booking";
import { FinishYourBookingProps } from "./typings";

export const FinishYourBooking = (props: FinishYourBookingProps) => {
  const { templateComponent } = props;

  if (!templateComponent) {
    return null;
  }

  return <FinishYourBookingSharedUI inputs={{}} />;
};

export default FinishYourBooking;
